import {
  Configuration,
  ReviewsApi,
} from './openapi'
import { baseOptions, mainInstance } from './axios'

export * from './openapi'
export const basePath
  = import.meta.env.VITE_NUXT_ENV_BACKEND_API_ROOT_URL
  || 'https://dev-api.dominter.net'

const config = new Configuration({
  basePath,
  baseOptions,
})
export const reviewsApi = new ReviewsApi(config, undefined, mainInstance)
